<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>项目班：</span>
        <el-select class="mr-20" v-model="queryParams.LayoutId" clearable>
          <el-option v-for="item in projectClassList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
        </el-select>
        <!-- <el-select class="mr-20" v-model="queryParams.JobId" filterable clearable>
          <el-option v-for="item in jobList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
        </el-select> -->
        <!-- <span>工作项目：</span>
        <el-select
          class="mr-20"
          v-model="queryParams.WorkItemId"
          filterable
          clearable
        >
          <el-option
            v-for="item in workItemList"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select> -->
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
      <div class="right">
        <el-button type="primary" icon="el-icon-upload" @click="$router.push(`/${baseRoute}/practice/project/create`)">新建工作项目</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border v-loading="loading" height="640px">
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :prop="item.prop" :width="item.prop === 'Content' ? '400' : ''">
          <template slot-scope="scope">
            <el-tag class="u-radius-16" v-if="item.prop === 'IsPublished'" :type="scope.row['IsPublished'] ? 'scuess' : 'warning'">
              {{ scope.row["IsPublished"] ? "已发布" : "未发布" }}</el-tag>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="300">
        <template slot-scope="scoped">
          <el-button type="text" icon="el-icon-edit-outline" class="btn-color" v-if="!scoped.row.IsPublished" @click="handleEdit(scoped.row.Id)">编辑</el-button>
          <el-button type="text" icon="el-icon-view" class="btn-color" @click="handleView(scoped.row.Id)">查看</el-button>
          <el-button type="text" icon="el-icon-edit-outline" class="btn-color" v-if="!scoped.row.IsPublished" @click="handlePublish(scoped.row.Id)">发布</el-button>
          <el-button type="text" icon="el-icon-delete" class="hover-danger" @click="handleDelete(scoped.row.Id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getPageList, deleteWorkItem, publish } from "@/api/internshipWorkItem";
import { getInternshipJobList } from "@/api/jobApply";
import { StaffTypeEnum } from "@/utils/constant";
import { getAllList as getProjectClassList } from "@/api/internshipLayout";
export default {
  data() {
    return {
      loading: false,
      queryParams: {
        PageSize: 999,
        PageIndex: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "项目班",
          prop: "LayoutName"
        },
        {
          label: "工作项名称",
          prop: "Name"
        },
        {
          label: "工作内容",
          prop: "Content"
        },
        {
          label: "开始时间",
          prop: "StartTime"
        },
        {
          label: "结束时间",
          prop: "EndTime"
        },
        {
          label: "是否发布",
          prop: "IsPublished"
        }
      ],
      jobList: [],
      workItemList: [],
      projectClassList: []
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    },
    baseRoute() {
      return this.RoleType === StaffTypeEnum.School ? "school" : "enterprise";
    }
  },
  created() {
    this.initialize();
    this.getProjectClassList();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        if (this.RoleType === StaffTypeEnum.Enterprise) {
          let temp = null;
          temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.EnterpriseId = temp.Employer.Id;
        } else {
          this.queryParams.EnterpriseId = null;
        }
        await this.getTableData();
        await this.getJobList();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    async getJobList() {
      const res = await getInternshipJobList({
        EnterpriseId: this.queryParams.EnterpriseId
      });
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    },
    async handlePublish(id) {
      const res = await publish(id);
      if (res.IsSuccess) {
        this.$message.success("发布成功");
        this.initialize();
      }
    },
    handleEdit(id) {
      this.$router.push(`/${this.baseRoute}/practice/project/edit?id=${id}`);
    },
    handleView(id) {
      this.$router.push(`/${this.baseRoute}/practice/project/view?id=${id}`);
    },
    handleDelete(id) {
      this.$confirm("此操作将会永久删除此数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteWorkItem(id);
          if (res.IsSuccess) {
            this.$message.success("删除成功");
            this.initialize();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    async getProjectClassList() {
      const { Result = [] } = await getProjectClassList();
      this.projectClassList = Result;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.table {
  overflow-x: auto;
}
</style>
