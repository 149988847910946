import request from "@/utils/request";
const controller = "/VocationalEducation/InternshipWorkItem";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getAllList = params =>
  request({
    url: `${controller}/GetAllList`,
    method: "get",
    params
  });
const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: {
      id
    }
  });

const insert = data =>
  request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
const update = data =>
  request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
const deleteWorkItem = id =>
  request({
    url: `${controller}/Delete`,
    method: "post",
    params: {
      id
    }
  });
const publish = id =>
  request({
    url: `${controller}/Publish`,
    method: "post",
    params: {
      id
    }
  });

export {
  getPageList,
  getAllList,
  getInfo,
  insert,
  update,
  deleteWorkItem,
  publish
};